
$.fn.datepicker.dates['hu'] = {
    days: ["Vasárnap", "Hétfő", "Kedd", "Szerda", "Csütörtök", "Péntek", "Szombat"],
    daysShort: ["Vas", "Hét", "Kedd", "Szer", "Csüt", "Pén", "Szo"],
    daysMin: ["V", "H", "K", "Sze", "Cs", "P", "Szo"],
    months: ["Január", "Február", "Március", "Április", "Május", "Június", "Július", "Augusztus", "Szeptember", "Október", "November", "December"],
    monthsShort: ["Jan", "Feb", "Már", "Ápr", "Máj", "Jún", "Júl", "Aug", "Szep", "Okt", "Nov", "Dec"],
    today: "Ma",
    clear: "Töröl",
    format: "yyyy/mm/dd",
    titleFormat: "yyyy MM", /* Leverages same syntax as 'format' */
    weekStart: 1
};
